<!-- 模板 -->
<template>
  <div class="">
    <div class="contant">
      <headers class="top" :active="3"></headers>
      <div class="details">
        <div class="wu wuli">
          <div>
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
              <el-breadcrumb-item :to="{ path: 'about' }"
                >黑人黑企</el-breadcrumb-item
              >
              <el-breadcrumb-item>详情</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div class="bon">
            <div class="tit">{{info.title}}</div>
            <div class="time">
              <span>{{info.publishtime}}</span> <span class="articl">{{info.seotitle}}</span>
            </div>
            <div v-html="info.content" class="neir">
              
            </div>
          </div>
        </div>
      </div>
      <footers></footers>
    </div>
  </div>
</template>

<script>
import request from "../utils/request.js";
import headers from "../assembly/head.vue"; //头部
import footers from "@/assembly/footer"; //页脚
// import sides from "@/assembly/side"; //侧边
export default {
  name: "",
  data() {
    return {
      id: "",
      info:{},
    };
  },
  props: [],
  components: {
    headers,
    // casesList,
    footers,
    // sides,
  },
  computed: {},
  filters: {},
  methods: {
    async detils() {
      let options = {
        url: "/index/detail",
        type: "post",
        data: {
          id: this.$route.query.id,
        },
      };
      try {
        let res = await request(options);
        console.log(res);
        if (res.data.code !== 1) {
          return this.$message.error("请求数据失败");
        }
        this.info = res.data.data
      } catch (error) {
        console.log(error);
        this.$message.error("请求数据失败");
      }
    },
  },
  created() {
    // this.id = this.$router.query.id
    this.detils();
  },
  mounted() {
    let _this = this;
    // 页面渲染结束再执行动画
    this.$nextTick(function () {
      new _this.WOW({ live: false }).init();
    });
  },
};
</script>

<style lang="less" scoped>
//@import url(); 引入公共css类
.contant {
  position: relative;
  .top {
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 10;
  }
  .details {
    min-height: 200px;
    background-color: #f7f8fa;
    .wuli {
      padding: 20px 0 172px;
      .bon {
        text-align: center;
        margin-top: 60px;
        .tit {
          font-size: 24px;
          font-weight: bold;
          line-height: 41px;
          color: #252121;
        }
        .time {
          font-size: 18px;
          font-weight: 400;
          line-height: 31px;
          color: #252121;
          opacity: 1;
          margin-top: 20px;
          .articl {
            margin-left: 20px;
          }
        }
      }
    }
  }
}
.neir{
  margin-top: 15px;
}
/deep/.neir p{
  background-color: #f7f8fa !important;
}
</style>
<style>
::-webkit-scrollbar {
  width: 0;
  height: 0;
}
</style>
